import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Button, Modal, Card } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit';
import paginationFactory, { PaginationProvider, PaginationListStandalone, SizePerPageDropdownStandalone } from 'react-bootstrap-table2-paginator';
import { Fetcher } from "../../../Helpers/fetcher.js";
import { Notify } from "../../../Helpers/notification.js";
import ReactTooltip from 'react-tooltip';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import Cookies from 'js-cookie';

import "./index.css";
import { set } from 'lodash';
class UserSettingList extends Component {
	constructor(props) {

		super(props);
		this.state = {
			groupList: [],
			confirmDelete: false,
			selectedGroupId: 0,
			showAddCreditModal: false,
			sizePerPage: 10,
            settingList : []
		}
	}
	componentDidMount() {
		this.getSetting();
		const savedSizePerPage = Cookies.get('groupListsizePerPage');
		if (savedSizePerPage) {
			this.setState({ sizePerPage: Number(savedSizePerPage) });
		}
	}


	getSetting = () => {
		const apiPath = this.props.app.apiPath;
        const userId = this.props.app.userId;

		Fetcher.get(apiPath+ '/api/obd/user/setting/list/'+userId)
			.then(res => {
				this.setState({
					settingList: res,
					showAddCreditModal: false,
					confirmDelete: false,
				})
			})
			.catch(err => { console.log('Error in fetching User Setting List', err) });
	}

	confirmDelete = (status, groupId) => {
		this.setState({
			confirmDelete: status,
			selectedGroupId: groupId
		});
	}

	setvalue = (e) => {
		this.setState({
			[e.target.name]: e.target.value,
		});
	}

	setMessageShow = (status) => {
		this.setState({
			showAddCreditModal: false,
		});
	}

	handleSizePerPageChange = (newSizePerPage) => {
		this.setState({ sizePerPage: newSizePerPage });
		Cookies.set('groupListsizePerPage', newSizePerPage);
	}

	render() {

		const settingList = this.state.settingList;
		var columns;
			columns = [
				{
					dataField: 'userId',
					text: 'User Id',
					sort: true,
					hidden: false
				},
				{
					dataField: 'username',
					text: 'User Name',
					sort: true,
					searchable: true
				},
				{
					dataField: 't1_margin',
					text: 'S1_Margin',
					sort: true,
					searchable: true
				},
				{
					dataField: 't1_interval',
					text: 'S1_Interval',
					sort: true,
					searchable: true
				},
				{
					dataField: 't1_threshold',
					text: 'S1_Threshold',
					sort: true,
					searchable: true
				},
				{
					dataField: 'tier1',
					text: 'Tier1',
					sort: true,
					searchable: true
				},
				{
					dataField: 't2_margin',
					text: 'S2_Margin',
					sort: true,
					searchable: true
				},
				{
					dataField: 't2_threshold',
					text: 'S2_Threshold',
					sort: true,
					searchable: true
				},
				{
					dataField: 't2_start_interval',
					text: 'S2_Start_Interval',
					sort: true,
					searchable: true
				},
				{
					dataField: 't2_end_interval',
					text: 'S2_End_Interval',
					sort: true,
					searchable: true
				},
				{
					dataField: 'tier2',
					text: 'Tier2',
					sort: true,
					searchable: true
				},
				{
					dataField: 't3_margin',
					text: 'S3_Margin',
					sort: true,
					searchable: true
				},
				{
					dataField: 't3_threshold',
					text: 'S3_Threshold',
					sort: true,
					searchable: true
				},
				{
					dataField: 't3_start_interval',
					text: 'S3_Start_Interval',
					sort: true,
					searchable: true
				},
				{
					dataField: 't3_end_interval',
					text: 'S3_End_Interval',
					sort: true,
					searchable: true
				},
				{
					dataField: 'tier3',
					text: 'Tier3',
					sort: true,
					searchable: true
				},
				{
					dataField: 't4_margin',
					text: 'S4_Margin',
					sort: true,
					searchable: true
				},
				{
					dataField: 't4_interval',
					text: 'S4_Interval',
					sort: true,
					searchable: true
				},
				{
					dataField: 't4_threshold',
					text: 'S4_Threshold',
					sort: true,
					searchable: true
				},
				{
					dataField: 'tier4',
					text: 'Tier4',
					sort: true,
					searchable: true
				},
				{
					dataField: 'action',
					text: 'Action',
					sort: false,
					searchable: false
				},];
		
		const setting = Array.isArray(settingList) ? settingList.map((setting, index) => {
			    let ids = setting.subId+":"+setting.userId;
				let action = [ <Link data-tip="Edit Setting" key={`${index}-edit-settings`} className="edit-settings" to={`${this.props.app.urlPrefix}/update-user_setting/${ids}`}><i className="fa fa-edit"></i></Link> ];
				//action.push(<a data-tip="Delete Group" key={`${index}-delete-group`} className="delete-group" onClick={(e) => { this.confirmDelete(true, groups.groupId) }} href="#"><i className="fa fa-trash"></i></a>);
				let t1 = "";
                let t2 = "";
				let t3 = "";
				let t4 = "";
                
				if(setting.tier1==="2"){
					t1="Yes"
				}else if(setting.tier1==="1"){
					t1 = "No"
				}

				if(setting.tier2==="2"){
					t2="Yes"
				}else if(setting.tier2==="1"){
					t2 = "No"
				}

				if(setting.tier3==="2"){
					t3="Yes"
				}else if(setting.tier3==="1"){
					t3 = "No"
				}

				if(setting.tier4==="2"){
					t4="Yes"
				}else if(setting.tier4==="1"){
					t4 = "No"
				}

				return {

					userId : setting.userId,
					username : setting.username,
					t1_margin : setting.t1_margin=="" ? "": (setting.t1_margin+"%"),
					t1_interval :  setting.t1_interval=="" ? "": setting.t1_interval+" sec",
					t1_threshold : setting.t1_threshold,
					tier1 : t1 ,
					t2_margin : setting.t2_margin=="" ? "": setting.t2_margin+"%",
					t2_threshold : setting.t2_threshold,
					t2_start_interval : setting.t2_start_interval=="" ? "": setting.t2_start_interval+" sec",
					t2_end_interval : setting.t2_end_interval=="" ? "": setting.t2_end_interval+" sec",
					tier2 : t2,
					t3_margin : setting.t3_margin=="" ? "": setting.t3_margin+"%",
					t3_threshold : setting.t3_threshold,
					t3_start_interval : setting.t3_start_interval=="" ? "": setting.t3_start_interval+" sec",
					t3_end_interval : setting.t3_end_interval=="" ? "": setting.t3_end_interval+" sec",
					tier3 : t3,
					t4_margin : setting.t4_margin=="" ? "": setting.t4_margin+"%",
					t4_interval : setting.t4_interval=="" ? "": setting.t4_interval+" sec",
					t4_threshold : setting.t4_threshold+"",
					tier4 : t4,
					action : action

				};
			
		}) : []
		const { SearchBar, ClearSearchButton } = Search;

		const { ExportCSVButton } = CSVExport;

		const defaultSorted = [{
			dataField: 'userId',
			order: 'desc'
		}];

		const paginationOption = {
			custom: true,
			totalSize: setting.length,
			sizePerPage: this.state.sizePerPage,
			sizePerPageList: [10, 25, 50, 100],
			onSizePerPageChange: this.handleSizePerPageChange
		};

		return (
			<Card>
				<Card.Header>User Setting List</Card.Header>
				<Card.Body>
					<div className="widget-list-table-cont">
						{<PaginationProvider pagination={paginationFactory(paginationOption)}>
							{
								({
									paginationProps,
									paginationTableProps
								}) => (
									<ToolkitProvider
										keyField="userId"
										data={setting}
										columns={columns}
										search
										bootstrap4
										exportCSV={ {
											fileName: 'users_settings.csv'
										  } }

									>
										{
											props => (
												<div className="">
													Show <SizePerPageDropdownStandalone className="search-dropdown" {...paginationProps} /> items
													<span className="search-cont"><SearchBar  {...props.searchProps} /></span>
													<ClearSearchButton className="btn-primary btn-round" {...props.searchProps} />
													{/*<span>&nbsp;&nbsp;&nbsp;</span><span className="float-none avail-credit-cont"><span className="credits-avail">Available Credits: {this.state.adminCredits}</span></span>*/}
													{/*<Link className="float-right btn btn-default btn-primary" to={`${this.props.app.urlPrefix}/add-cli`}>Allocate CLI</Link>*/}
													<ExportCSVButton className="export-csv btn-primary ml-2" {...props.csvProps}>Export to CSV</ExportCSVButton>
													<BootstrapTable defaultSorted={defaultSorted} bordered={true} hover condensed wrapperClasses="table-responsive" classes="mt-3  widgetlist-table"  {...props.baseProps}  {...paginationTableProps} noDataIndication={() => (<div>{'No data available'}</div>)}/>
													<PaginationListStandalone {...paginationProps} />
													<ReactTooltip />
												</div>
											)
										}
									</ToolkitProvider>
								)
							}
						</PaginationProvider>}
					</div>

					

				</Card.Body>
			</Card>

		);
	}
}

export default UserSettingList;