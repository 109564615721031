import React, { Component } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { cloneDeep, isEmpty } from 'lodash';
import { Fetcher } from "../../../Helpers/fetcher.js";
import { Notify } from "../../../Helpers/notification.js";
import { Redirect } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../index.css";

// import "./index.css";
class UpdateUserSetting extends Component {
	constructor(props) {
		super(props);
		this.state = {
			
			redirectToUserManagement: false,
			Setting1: false,
			Setting2: false,
			Setting3: false,
			Setting4: false,
            subId: "",
            t1_margin: "",
            t1_interval: "",
            t1_threshold: "",
            tier1: "",
            t2_margin: "",
            t2_threshold: "",
            t2_start_interval: "",
            t2_end_interval: "",
            tier2: "",
            t3_margin: "",
            t3_threshold: "",
            t3_start_interval: "",
            t3_end_interval: "",
            tier3: "",
            t4_margin: "",
            t4_interval: "",
            t4_threshold: "",
            tier4: "",
			uId : ""
		};

	}
	componentDidMount() {
		const apiPath = this.props.app.apiPath;
		const userId = this.props.selectedIds;
		const parent = this.props.app.userId;
        let arr = userId.split(":");
        console.log(">>>>>>>"+arr[0]);
		if(arr.length == 2){
		this.setState({
			subId : arr[0],
			uId : arr[1]
		})
	    }
        if(arr[0] !=0){
		Fetcher.get(apiPath + '/api/obd/user/setting/' + arr[0]+"/"+arr[1])
			.then(res => {

                console.log(JSON.stringify(res));
				this.setState({
					  t1_margin : res.t1_margin,
                      t1_interval : res.t1_interval,
                      t1_threshold : res.t1_threshold,
                      tier1 : res.tier1,
                      Setting1 : (res.t1_margin!==""? true : false),
                      t2_margin : res.t2_margin,
                      t2_start_interval : res.t2_start_interval,
                      t2_end_interval : res.t2_end_interval,
                      t2_threshold : res.t2_threshold,
                      tier2 : res.tier2,
                      Setting2 : (res.t2_margin!==""? true : false),
                      t3_margin : res.t3_margin,
                      t3_start_interval : res.t3_start_interval,
                      t3_end_interval : res.t3_end_interval,
                      t3_threshold : res.t3_threshold,
                      tier3 : res.tier3,
                      Setting3 : (res.t3_margin!==""? true : false),
                      t4_margin : res.t4_margin,
                      t4_interval : res.t4_interval,
                      t4_threshold : res.t4_threshold, 
                      tier4  : res.tier4,
                      Setting4 : (res.t4_margin!==""? true : false),
				})
			})
			.catch(err => { console.log('Error in User Setting', err) });
        }
		
		
	}

	setvalue = (e) => {
		this.setState({
			[e.target.name]: e.target.value,
		});
	}
	hideModal = () => {
		this.setState({
			redirectToUserManagement: this.state.redirectToUserManagement
		});
	}
	addZero = (i) => {
		if (i < 10) {
			i = "0" + i;
		}
		return i;
	}

	updateUser = (event) => {
		event.preventDefault();
		const state = cloneDeep(this.state);

		if(state.t2_start_interval > state.t2_end_interval){
			Notify.notify('Setting 2 starting range cannot be greater than or equal to ending range')  
			return;
		}

		if(state.t3_start_interval > state.t3_end_interval){
			Notify.notify('Setting 2 starting range cannot be greater than or equal to ending range')
		    return;
		}

		console.log("Setting 1 : "+ this.state.Setting1);
		console.log("Setting 2 : "+ this.state.Setting2);
        console.log("Setting 3 : "+ this.state.Setting3);
		console.log("Setting 4 : "+ this.state.Setting4);

		let s_t1_margin = "";
		let s_t1_interval = "";
		let s_t1_threshold ="";
		let s_tier1 = "";

		if(this.state.Setting1===false){
				s_t1_margin = "";
                s_t1_interval = "";
                s_t1_threshold = "";
                s_tier1 = "1";
		}else{
			    s_t1_margin = this.state.t1_margin;
                s_t1_interval = this.state.t1_interval;
                s_t1_threshold = this.state.t1_threshold;
                s_tier1 = "2";
		}
		
        let s_t2_margin =  "";
        let s_t2_threshold="";
		let s_t2_start_interval="";
		let s_t2_end_interval ="";
		let s_tier2="";

		if(this.state.Setting2===false){
             s_t2_margin =  "";
             s_t2_threshold="";
		     s_t2_start_interval="";
		     s_t2_end_interval ="";
		     s_tier2="1";
		}else{
			 s_t2_margin =  this.state.t2_margin;
             s_t2_threshold= this.state.t2_threshold;
		     s_t2_start_interval= this.state.t2_start_interval;
		     s_t2_end_interval = this.state.t2_end_interval;
		     s_tier2="2";
		}

		let s_t3_margin =  "";
        let s_t3_threshold="";
		let s_t3_start_interval="";
		let s_t3_end_interval ="";
		let s_tier3="";

		if(this.state.Setting3===false){
             s_t3_margin =  "";
             s_t3_threshold="";
		     s_t3_start_interval="";
		     s_t3_end_interval ="";
		     s_tier3="1";
		}else{
			 s_t3_margin =  this.state.t3_margin;
             s_t3_threshold= this.state.t3_threshold;
		     s_t3_start_interval= this.state.t3_start_interval;
		     s_t3_end_interval = this.state.t3_end_interval;
		     s_tier3="2";
		}

		
		let s_t4_margin = "";
		let s_t4_interval = "";
		let s_t4_threshold ="";
		let s_tier4 = "";

		if(this.state.Setting4===false){
				s_t4_margin = "";
                s_t4_interval = "";
                s_t4_threshold = "";
                s_tier4 = "1";
		}else{
			    s_t4_margin = this.state.t4_margin;
                s_t4_interval = this.state.t4_interval;
                s_t4_threshold = this.state.t4_threshold;
                s_tier4 = "2";
		}

		const data = {
                      userId :  this.state.uId,
					  subId : this.state.subId,
			          t1_margin : s_t1_margin,
                      t1_interval : s_t1_interval,
                      t1_threshold : s_t1_threshold,
                      tier1 : s_tier1,
                      t2_margin : s_t2_margin,
                      t2_start_interval :s_t2_start_interval,
                      t2_end_interval : s_t2_end_interval,
                      t2_threshold : s_t2_threshold,
                      tier2 : s_tier2,
                      t3_margin : s_t3_margin,
                      t3_start_interval : s_t3_start_interval,
                      t3_end_interval : s_t3_end_interval,
                      t3_threshold : s_t3_threshold,
                      tier3 : s_tier3,
                      t4_margin : s_t4_margin,
                      t4_interval : s_t4_interval,
                      t4_threshold : s_t4_threshold, 
                      tier4  : s_tier4
		}
        this.saveData(data);
		
	}
	saveData = (data) => {
		// this.props.app.apiPath
		return Fetcher.post(this.props.app.apiPath + '/api/obd/update/user/setting', { headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
			.then(res => {
				let modalHeading;
				let redirectToUserManagement;
				if (res.message === 'Parameters Incorrect' || res.message === 'User Updation Failed') {
					redirectToUserManagement = false;
				} else {
					redirectToUserManagement = true;
					Notify.notify(res.message);
				}
				this.setState({
					redirectToUserManagement: redirectToUserManagement
				})

			})
			.catch(err => {
				console.log('Error in Updating User Setting', err)
				Notify.notify('Error in Updating User Setting');
			});

	}

	toggleChange = (e, name) => {
		this.setState({
			[name]: !this.state[name],
		});
	}

	toggleCheckedChange = (e, name) => {
		this.setState({
			[name]: e.target.checked,
		});
	};

	renderSettingOption = (optionName) => {
		const isAdmin = this.props.app.role === 'ROLE_SADMIN';
		const optionState = this.state[optionName];
		const marginValue = this.state[`t${optionName.slice(-1)}_margin`];
		const thresholdValue = this.state[`t${optionName.slice(-1)}_threshold`];
		const isTier2or3 = optionName === 'Setting2' || optionName === 'Setting3';
		let intervalValue;
        if (!isTier2or3) {
          intervalValue = this.state[`t${optionName.slice(-1)}_interval`];
        }
	
		return (
			<div>
				<Row>
					{isAdmin && (
						<Col sm={3}>
							<Form.Group className="form-group">
								<Form.Check
									checked={optionState}
									onChange={(e) => this.toggleCheckedChange(e, optionName)}
									custom
									inline
									name={optionName}
									label={`${optionName}`}
									id={optionName}
									type="checkbox"
								/>
							</Form.Group>
						</Col>
					)}
				</Row>
				<Row>
					{optionState && isAdmin && (
						<>
							<Col sm={3}>
								<Form.Group className="form-group required control-label">
									<Form.Select
										className="dd"
										required
										name={`t${optionName.slice(-1)}_margin`}
										value={marginValue}
										onChange={(e) => this.setvalue(e)}
										as="select"
									>
										<option value="">Select Margin</option>
										{[10, 20, 30, 40, 50, 60, 70, 80, 90, 100].map((percent, index) => (
											<option key={index} value={index + 1}>{percent}%</option>
										))}
									</Form.Select>
								</Form.Group>
							</Col>
							{isTier2or3 && (
								<>
									<Col sm={3}>
										<Form.Group className="form-group required control-label">
										<Form.Select
                                            className="dd"
                                            required
                                            name={`t${optionName.slice(-1)}_start_interval`}
                                            value={this.state[`t${optionName.slice(-1)}_start_interval`]}
                                            onChange={(e) => this.setvalue(e)}
                                            as="select"        
                                        >        
                                            <option value="">Starting Range</option>
                                            {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((seconds) => (
                                                <option key={seconds} value={seconds}>{seconds} sec</option>
                                            ))}        
                                        </Form.Select>
										</Form.Group>
									</Col>
									<Col sm={3}>
										<Form.Group className="form-group required control-label">
										<Form.Select
                                            className="dd"
                                            required
                                            name={`t${optionName.slice(-1)}_end_interval`}
                                            value={this.state[`t${optionName.slice(-1)}_end_interval`]}
                                            onChange={(e) => this.setvalue(e)}
                                            as="select"
                                        >
                                            <option value="">Ending Range</option>
                                            {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((seconds) => (
                                              <option key={seconds} value={seconds}>{seconds} sec</option>
                                            ))}
                                        </Form.Select>
										</Form.Group>
									</Col>
								</>
							)}
							{!isTier2or3 && (
								<>
									<Col sm={3}>
										<Form.Group className="form-group required control-label">
											<Form.Select
												className="dd"
												required
												name={`t${optionName.slice(-1)}_interval`}
												value={intervalValue}
												onChange={(e) => this.setvalue(e)}
												as="select"
											>
												<option value="">Select Interval</option>
												{[1, 2, 3, 4, 5, 6, 7, 8, 9].map((seconds) => (
													<option key={seconds} value={seconds}>{seconds} sec</option>
												))}
											</Form.Select>
										</Form.Group>
									</Col>
								</>
							)}
							<Col sm={3}>
								<Form.Group className="form-group required control-label">
									<Form.Control
										required
										name={`t${optionName.slice(-1)}_threshold`}
										type="number"
										placeholder="Threshold"
										onChange={(e) => this.setvalue(e)}
										value={thresholdValue}
									/>
								</Form.Group>
							</Col>
						</>
					)}
				</Row>
			</div>
		);
	};

	render() {
		if (this.state.redirectToUserManagement === true) {
			return <Redirect to={'/user-settings'} />
		}
		
		return (

			<Form method="post" onSubmit={this.updateUser}>
				<Row>
					<Col sm={12}>
						<Card>
							<Card.Header>Update User Setting</Card.Header>
							<Card.Body>
									
								<Row>
								<Row>
								  {/* {this.renderCuttingOption('Cutting1')} */}
								  {this.renderSettingOption('Setting1')}
								  {this.renderSettingOption('Setting2')}
								  {this.renderSettingOption('Setting3')}
								  {this.renderSettingOption('Setting4')}
								</Row>
								</Row>

								<Button className="btn-round" variant="primary" type="submit">Update User</Button>
							</Card.Body>
						</Card>
					</Col>
				</Row>
			</Form>
		);
	}

}
export default UpdateUserSetting;