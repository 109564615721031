import React, { Component } from 'react';
import { Card, Col, Row, Form, Button } from 'react-bootstrap';
import { Fetcher } from '../../Helpers/fetcher.js';
import ToolkitProvider, { Search,CSVExport } from 'react-bootstrap-table2-toolkit';
import {PieChart, Pie, BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationListStandalone, SizePerPageDropdownStandalone } from 'react-bootstrap-table2-paginator';
import './index.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Notify } from "../../Helpers/notification.js";
import { FaUsers, FaUserTie } from 'react-icons/fa';
import Dropdown from 'react-bootstrap/Dropdown';
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie';
import { cloneDeep, isEmpty } from 'lodash';

class Dashboard extends Component {
	constructor(props) {
		super(props);

		const currentDate = new Date();
        const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);

		this.state = {
			userCampaignList: [],
			todayCampaignList: [],
			weeklyCampaignList: [],
			runningCampaignList: [],
			statisticSummaryList: [],
			campaignAnalysisList: [],
			expenditureList: [],
			campaignList: [],
			dashboardData: [],
			startDate: firstDayOfMonth,
            endDate: currentDate,
			startDateCampaignAnalysis: firstDayOfMonth,
			endDateCampaignAnalysis: currentDate,
			selectedCampaignType: "",
			selectedCampaignName: "",
			resellerRunningCampaignList: [],
			startDateCallDetails: firstDayOfMonth,
			endDateCallDetails: currentDate,
			callDetailsList: {},
			startDateDialerUsage: firstDayOfMonth,
			endDateDialerUsage: currentDate,
			dialerUsageList: [],
			todayCampaignList_S_P_P: 10,
			todayLiveCampSumm_S_P_P: 10,
			statSummary_S_P_P: 10,
			campaignAnalysis_S_P_P: 10,
			callDetail_S_P_P: 10,
			dialerUsage_S_P_P: 10,
			sizePerPageLiveCampaign: 25,
			resellerUserList : [],
			campAnalysisUsername: '',
			noDataDisplayMsg: 'No data available',
		}
		this.viewCampaignRef = React.createRef()
	}
	componentDidMount() {
		const apiPath = this.props.app.apiPath;
		const userId = this.props.app.userId;
		Fetcher.get(apiPath + '/api/obd/dashboard/' + userId)
			.then(res => {
				this.setState({
					dashboardData: res.dbHeader
				})
			})
			.catch(err => { console.log('Error in fetching Dashboard Data', err) });
		this.getUsers();
		this.getUserStatisticSummary();
		this.getUserCampaignAnalysis();
		this.getResellerRunningCampaigns();
		this.superAdminGetCallDetails();
		this.superAdminGetDialerUsage();
		this.getResellersummaryUsers();
		const todayCampaignList_S_P_P = Cookies.get('todayCampaignList_S_P_P');
		if (todayCampaignList_S_P_P) {
			this.setState({ todayCampaignList_S_P_P: Number(todayCampaignList_S_P_P) });
		}
		const sizePerPageLiveCampaign = Cookies.get('sizePerPageLiveCampaign');
		if (sizePerPageLiveCampaign) {
			this.setState({ sizePerPageLiveCampaign: Number(sizePerPageLiveCampaign) });
		}
		const todayLiveCampSumm_S_P_P = Cookies.get('todayLiveCampSumm_S_P_P');
		if (todayLiveCampSumm_S_P_P) {
			this.setState({ todayLiveCampSumm_S_P_P: Number(todayLiveCampSumm_S_P_P) });
		}
		const statSummary_S_P_P = Cookies.get('statSummary_S_P_P');
		if (statSummary_S_P_P) {
			this.setState({ statSummary_S_P_P: Number(statSummary_S_P_P) });
		}
		const campaignAnalysis_S_P_P = Cookies.get('campaignAnalysis_S_P_P');
		if (campaignAnalysis_S_P_P) {
			this.setState({ campaignAnalysis_S_P_P: Number(campaignAnalysis_S_P_P) });
		}
		const callDetail_S_P_P = Cookies.get('callDetail_S_P_P');
		if (callDetail_S_P_P) {
			this.setState({ callDetail_S_P_P: Number(callDetail_S_P_P) });
		}
		const dialerUsage_S_P_P = Cookies.get('dialerUsage_S_P_P');
		if (dialerUsage_S_P_P) {
			this.setState({ dialerUsage_S_P_P: Number(dialerUsage_S_P_P) });
		}
	}	

	getUsers = () => {
		const apiPath = this.props.app.apiPath;
		const userId = this.props.app.userId;
		Fetcher.get(apiPath + '/api/obd/dashboard/' + userId)
			.then(res => {
				this.setState({
					userCampaignList: res.dbUserWiseCampaigns,
					todayCampaignList: res.dbTodayCampaigns,
					weeklyCampaignList: res.dbWeeklyCampaigns,
					runningCampaignList: res.runningCampaigns,
					expenditureList: res.dbExpenditure,
					showModal: false,
					showAddCreditModal: false,
					showPwdModal: false,
					confirmDelete: false,
				})
			})
			.catch(err => { console.log('Error in fetching Dashboard Data', err) });
	}

	getUserStatisticSummary = () => {
		if(this.props.app.role === 'ROLE_USER' || this.props.app.role === 'ROLE_ADMIN')
		{
			const apiPath = this.props.app.apiPath;
			const userId = this.props.app.userId;
			const data = {
				userId:userId,
				startDate : this.formatDate(this.state.startDate),
				endDate : this.formatDate(this.state.endDate)
			}
		
			Fetcher.post(apiPath + '/api/obd/user/statistic/summary',{ headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
			.then(res => {
				this.setState({
					statisticSummaryList: res
				})
			})
			.catch(err => { console.log('Error in fetching user statistic summary', err) });
		}
	}

	getUserCampaignAnalysis = () => {
		if(this.props.app.role === 'ROLE_USER' || this.props.app.role === 'ROLE_ADMIN')
		{
			const apiPath = this.props.app.apiPath;
			const userId = this.props.app.userId;
			const data = {
				userId:userId,
				startDate : this.formatDate(this.state.startDateCampaignAnalysis),
				endDate : this.formatDate(this.state.endDateCampaignAnalysis),
				campaignType: "All",
				campaignName: "All",
				username: this.state.campAnalysisUsername
			}
		
			Fetcher.post(apiPath + '/api/obd/campaign/analysis',{ headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
			.then(res => {
				this.setState({
					campaignAnalysisList: res
				})
			})
			.catch(err => { console.log('Error in fetching user campaign analysis', err) });
		}
	}

	campaignSummary = (userId) => {
		Fetcher.get(this.props.app.apiPath + '/api/obd/campaign/sadmin/' + userId)
			.then(res => {
				this.setState({
					campaignList: res,
					confirmStop: false,
					confirmPause: false,
					confirmResume: false,
					confirmRetry: false
				})
				this.executeScroll();
			})
			.catch(err => { console.log('Error in fetching Campaigns', err) });
	}

	getResellerRunningCampaigns = () => {
		if(this.props.app.role === 'ROLE_ADMIN') {
			const userId = this.props.app.userId;
			Fetcher.get(this.props.app.apiPath + '/api/obd/reseller/campaign/list/' + userId)
				.then(res => {
					this.setState({
						resellerRunningCampaignList: res,
					})
				})
				.catch(err => { console.log('Error in fetching reseller Campaigns', err) });
		}
	}

	getResellersummaryUsers = () => {
        if(this.props.app.role === 'ROLE_ADMIN') {
			const apiPath = this.props.app.apiPath;
			const userId = this.props.app.userId;
			Fetcher.get(apiPath + '/api/obd/reseller/users/'+userId)
				.then(res => {
					this.setState({
						resellerUserList: res.sort( (val1,val2) => {
							if(val1.username > val2.username) return 1;
							else if(val1.username < val2.username) return -1;
							else return 0;
						} )
					})
				})
				.catch(err => { console.log('Error in fetching Reseller Users List', err) });
		}		
    }

	superAdminGetCallDetails = () => {
		if(this.props.app.role === 'ROLE_SADMIN')
		{
			const apiPath = this.props.app.apiPath;
			const userId = this.props.app.userId;
			const data = {
				userId:userId,
				startDate : this.formatDate(this.state.startDateCallDetails),
				endDate : this.formatDate(this.state.endDateCallDetails)
			}
		
			Fetcher.post(apiPath + '/api/obd/dashboard/totalCalls',{ headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
			.then(res => {
				this.setState({
					callDetailsList: res
				})
			})
			.catch(err => { console.log('Error in fetching call details', err) });
		}
	}

	superAdminGetDialerUsage = () => {
		if(this.props.app.role === 'ROLE_SADMIN')
		{
			const apiPath = this.props.app.apiPath;
			const userId = this.props.app.userId;
			const data = {
				userId:userId,
				startDate : this.formatDate(this.state.startDateDialerUsage),
				endDate : this.formatDate(this.state.endDateDialerUsage)
			}
		
			Fetcher.post(apiPath + '/api/obd/dialerwise/totalUsage',{ headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
			.then(res => {
				this.setState({
					dialerUsageList: res
				})
			})
			.catch(err => { console.log('Error in fetching dialer usage', err) });
		}
	}

	handleDateChange = (key, date) => {
		this.setState({
		  [key]: date,
		});
	};

	formatDate = (date) => {
		const year = date.getFullYear();
		const month = String(date.getMonth() + 1).padStart(2, '0');
		const day = String(date.getDate()).padStart(2, '0');
	
		return `${year}-${month}-${day}`;
	};

	searchStatSummary = async (e) => {
        e.preventDefault();
        let sDate = "";
        let eDate ="";
        if(this.state.startDate === ""){
                 sDate = this.state.startDate
        }else{
            sDate =  this.formatDate(this.state.startDate)
        }
        if(this.state.endDate === ""){
                 eDate = this.state.endDate
        }else{
            eDate =  this.formatDate(this.state.endDate)
        }
		if (new Date(sDate) > new Date(eDate)) {
			Notify.notify("Start date cannot be greater than end date.");
			return;
		}
        const apiPath = this.props.app.apiPath;
		const userId = this.props.app.userId;
		const data = {
            userId:userId,
            startDate : sDate,
            endDate : eDate,
        }
		
		Fetcher.post(apiPath + '/api/obd/user/statistic/summary',{ headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
		.then(res => {
			this.setState({
				statisticSummaryList: res
			})
		})
		.catch(err => { console.log('Error in fetching user statistic summary', err) });
		
    }

	searchCallDetails = async (e) => {
        e.preventDefault();
        let sDate = "";
        let eDate ="";
        if(this.state.startDateCallDetails === ""){
                 sDate = this.state.startDateCallDetails
        }else{
            sDate =  this.formatDate(this.state.startDateCallDetails)
        }
        if(this.state.endDateCallDetails === ""){
                 eDate = this.state.endDateCallDetails
        }else{
            eDate =  this.formatDate(this.state.endDateCallDetails)
        }
		if (new Date(sDate) > new Date(eDate)) {
			Notify.notify("Start date cannot be greater than end date.");
			return;
		}
        const apiPath = this.props.app.apiPath;
		const userId = this.props.app.userId;
		const data = {
            userId:userId,
            startDate : sDate,
            endDate : eDate,
        }
		
		Fetcher.post(apiPath + '/api/obd/dashboard/totalCalls',{ headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
		.then(res => {
			this.setState({
				callDetailsList: res
			})
		})
		.catch(err => { console.log('Error in fetching call details', err) });
		
    }

	searchDialerUsage = async (e) => {
        e.preventDefault();
        let sDate = "";
        let eDate ="";
        if(this.state.startDateDialerUsage === ""){
                 sDate = this.state.startDateDialerUsage
        }else{
            sDate =  this.formatDate(this.state.startDateDialerUsage)
        }
        if(this.state.endDateDialerUsage === ""){
                 eDate = this.state.endDateDialerUsage
        }else{
            eDate =  this.formatDate(this.state.endDateDialerUsage)
        }
		if (new Date(sDate) > new Date(eDate)) {
			Notify.notify("Start date cannot be greater than end date.");
			return;
		}
        const apiPath = this.props.app.apiPath;
		const userId = this.props.app.userId;
		const data = {
            userId:userId,
            startDate : sDate,
            endDate : eDate,
        }
		
		Fetcher.post(apiPath + '/api/obd/dialerwise/totalUsage',{ headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
		.then(res => {
			this.setState({
				dialerUsageList: res
			})
		})
		.catch(err => { console.log('Error in fetching dialer usage', err) });
		
    }

	searchCampaignAnalysis = async (e) => {
        e.preventDefault();
        let sDate = "";
        let eDate ="";
		let cType = "";
		let cName = "";
        if(this.state.startDateCampaignAnalysis === ""){
                 sDate = this.state.startDate
        }else{
            sDate =  this.formatDate(this.state.startDateCampaignAnalysis)
        }
        if(this.state.endDateCampaignAnalysis === ""){
                 eDate = this.state.endDateCampaignAnalysis
        }else{
            eDate =  this.formatDate(this.state.endDateCampaignAnalysis)
        }
		if (new Date(sDate) > new Date(eDate)) {
			Notify.notify("Start date cannot be greater than end date.");
			return;
		}
		
		if(this.state.selectedCampaignType === ""){
			cType = "All"
		}
		else{
            cType =  this.state.selectedCampaignType
        }
		if(this.state.selectedCampaignName === ""){
			cName = "All"
		}
		else{
            cName =  this.state.selectedCampaignName
        }
        const apiPath = this.props.app.apiPath;
		const userId = this.props.app.userId;
		const data = {
            userId:userId,
            startDate : sDate,
            endDate : eDate,
			campaignType: cType,
			campaignName: cName,
			username: this.state.campAnalysisUsername
        }
		
		Fetcher.post(apiPath + '/api/obd/campaign/analysis',{ headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
		.then(res => {
			this.setState({
				campaignAnalysisList: res
			})
		})
		.catch(err => { console.log('Error in fetching user campaign analysis', err) });
		
    }

	setvalue = (e) => {
		this.setState({
			[e.target.name]: e.target.value,
		});
	}

	handleSizePerPageChange = (newSizePerPage, key) => {
		this.setState({ [key]: newSizePerPage });
		Cookies.set(key, newSizePerPage);
	}

	executeScroll = () => this.viewCampaignRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
	
	render() {
		const todayCampaignList = this.state.todayCampaignList;
		var todayColumns;
		if (this.props.app.role === 'ROLE_SADMIN') {
			todayColumns = [
				{
					dataField: 'username',
					text: 'Username',
					sort: true,
					searchable: true
				},
				{
					dataField: 'campaignName',
					text: 'Campaign Name',
					sort: true,
					searchable: true
				},
				{
					dataField: 'campaignStatus',
					text: 'Status',
					sort: true,
					searchable: true
				},
				{
					dataField: 'scheduleDate',
					text: 'Date',
					sort: true,
					searchable: true
				},];
		}
		const todayCampaigns = Array.isArray(todayCampaignList) ? todayCampaignList.map((tcampaigns, index) => {
			let status = '';

			switch (tcampaigns.campaignStatus) {
				case 0:
					status = 'Ready';
					break;
				case 21:
					status = 'Processing';
					break;
				case 1:
					status = 'Running';
					break;
				case 2:
					status = 'Complete';
					break;
				case 3:
					status = 'Low Balance';
					break;
				case 4:
					status = 'Error';
					break;
				case 5:
					status = 'Error';
					break;
				case 6:
					status = 'Cancelling';
					break;
				case 7:
					status = 'Cancelled';
					break;
				case 8:
					status = 'Pausing';
					break;
				case 9:
					status = 'Paused';
					break;
				case 10:
					status = 'Resuming';
					break;
				case 22:
					status = 'Waiting For Channels';
						break;
				default:
					status = '';
					break;
			}
				return {
					username: tcampaigns.username,
					campaignName: tcampaigns.campaignName,
					campaignStatus: status,
					scheduleDate: tcampaigns.scheduleDate
				};
			}) : []

		const paginationOption1 = {
			custom: true,
			totalSize: todayCampaigns.length,
			sizePerPage: this.state.todayCampaignList_S_P_P,
			sizePerPageList: [10, 25, 50, 100],
			onSizePerPageChange: (newSizePerPage) => this.handleSizePerPageChange(newSizePerPage, 'todayCampaignList_S_P_P')
		};

		const userCampaignList = this.state.userCampaignList;
		var columns;
		if (this.props.app.role === 'ROLE_SADMIN') {
			columns = [
				{
					dataField: 'username',
					text: 'Username',
					sort: true,
					searchable: true
				},
				{
					dataField: 'totalCampaigns',
					text: 'Running Campaigns',
					sort: true,
					searchable: true
				},
				{
					dataField: 'action',
					text: 'Action',
					sort: true,
					searchable: true
				},];
		}
		else if (this.props.app.role === 'ROLE_ADMIN'){
			columns = [
				{
					dataField: 'username',
					text: 'Username',
					sort: true,
					searchable: true
				},
				{
					dataField: 'totalCampaigns',
					text: 'Running Campaigns',
					sort: true,
					searchable: true
				},];
		}
		const users = Array.isArray(userCampaignList) ? userCampaignList.map((user, index) => {
			if (this.props.app.role === 'ROLE_SADMIN') {
				let action=[];
				action.push( <a data-tip="" key={`${user.userId}`} className="btn btn-sm btn-primary" onClick={( e ) => { this.campaignSummary(user.userId) }} href="#">View Campaigns</a>);
				return {
					username: user.username,
					totalCampaigns: user.totalCampaigns,
					action:action
				};
			}
			else {
				return {
					username: user.username,
					totalCampaigns: user.totalCampaigns
				};
			}
		}) : []
		const { SearchBar, ClearSearchButton } = Search;

		const paginationOption = {
			custom: true,
			totalSize: users.length,
			sizePerPage: this.state.sizePerPageLiveCampaign,
			sizePerPageList: [10, 25, 50, 100],
			onSizePerPageChange: (newSizePerPage) => this.handleSizePerPageChange(newSizePerPage, 'sizePerPageLiveCampaign')
		};
		const defaultSorted1 = [{
			dataField: 'scheduleDate',
			order: 'desc'
		}];

		const campaigns = this.state.campaignList;
		const ccolumns = [
			{
				dataField: 'id',
				text: 'Serial No.',
				sort: false,
				hidden: true
			},
			{
				dataField: 'campaignId',
				text: '',
				sort: false,
				hidden: true
			},
			{
				dataField: 'name',
				text: 'Campaign Name',
				sort: true,
				searchable: true
			}, {
				dataField: 'campaignstatus',
				text: 'Campaign Status',
				sort: true,
				searchable: true
			}, {
				dataField: 'templateId',
				text: 'Campaign Type',
				sort: true,
				searchable: true
			},{
				dataField: 'scheduleTime',
				text: 'Start Time',
				sort: true,
				searchable: false
			},{
				dataField: 'endTime',
				text: 'End Time',
				sort: true,
				searchable: false
			}, {
				dataField: 'numbersUploaded',
				text: 'Total Numbers',
				sort: false,
				searchable: false
			}, {
				dataField: 'numbersProcessed',
				text: 'Calls Dialed',
				sort: false,
				searchable: false
			}, {
				dataField: 'callsSubmitted',
				text: 'Pending Calls',
				sort: false,
				searchable: false
			},{
				dataField: 'callsConnected',
				text: 'Connected Calls',
				sort: false,
				searchable: false
			},{
				dataField: 'pulses',
				text: 'Total Pulses',
				sort: true,
				searchable: false
			},{
				dataField: 'dndCount',
				text: 'DnD Count',
				sort: true,
				searchable: false
			},{
				dataField: 'dtmfCount',
				text: 'Dtmf Count',
				sort: true,
				searchable: false
			},{
				dataField: 'smsCount',
				text: 'SMS Count',
				sort: true,
				searchable: false
			},{
				dataField: 'retryCount',
				text: 'Retry Count',
				sort: true,
				searchable: false
			},{
				dataField: 'retries',
				text: 'No. Of retries',
				sort: true,
				searchable: false
			},];

		const products = Array.isArray(campaigns) ? campaigns.map((campaign, index) => {
			let template = '';

			switch (campaign.templateId) {
				case 0:
					template = 'Simple IVR';
					break;
				case 1:
					template = 'DTMF';
					break;
				case 2:
					template = 'Call Patch';
					break;
				default:
					template = '';
					break;
			}
			let status = '';

			switch (campaign.campaignStatus) {
				case 0:
					status = 'Ready';
					break;
				case 21:
					status = 'Processing';
					break;
				case 1:
					status = 'Running';
					break;
				case 2:
					status = 'Complete';
					break;
				case 3:
					status = 'Low Balance';
					break;
				case 4:
					status = 'Error';
					break;
				case 5:
					status = 'Error';
					break;
				case 6:
					status = 'Cancelling';
					break;
				case 7:
					status = 'Cancelled';
					break;
				case 8:
					status = 'Pausing';
					break;
				case 9:
					status = 'Paused';
					break;
				case 10:
					status = 'Resuming';
					break;
				case 22:
					status = 'Waiting For Channels';
						break;
				default:
					status = '';
					break;
			}
			return {
				id: index + 1,
				campaignId: campaign.campaignId,
				name: campaign.campaignName,
				campaignstatus: status,
				templateId: template,
				scheduleTime: campaign.scheduleTime,
				endTime: campaign.endTime,
				numbersUploaded: campaign.numbersUploaded,
				numbersProcessed: campaign.numbersProcessed,
				callsSubmitted: campaign.numbersUploaded - campaign.dndCount - campaign.numbersProcessed,
				callsConnected: campaign.callsConnected,
				pulses: campaign.pulses,
				dndCount: campaign.dndCount,
				dtmfCount: campaign.dtmfCount,
				smsCount: campaign.smsCount,
				retryCount: campaign.retryCount,
				retries: campaign.retries
			};
		}) : []
		const defaultSorted = [{
			dataField: 'scheduletime',
			order: 'desc'
		}];

		let runningCampaigns = [];
        let runningColumns ;
		let runningCampaignSummary = [];

		if(this.props.app.role === 'ROLE_USER' || this.props.app.role === 'ROLE_SADMIN') {
			runningCampaigns = this.state.runningCampaignList;
			runningColumns = [
				{
					dataField: 'id',
					text: 'Serial No.',
					sort: false,
					hidden: true
				},
				{
					dataField: 'campaignId',
					text: '',
					sort: false,
					hidden: true
				},
				{
					dataField: 'name',
					text: 'Campaign Name',
					sort: true,
					searchable: true
				},{
					dataField: 'scheduleTime',
					text: 'Start Time',
					sort: true,
					searchable: false
				},{
					dataField: 'numbersUploaded',
					text: 'Total Numbers',
					sort: false,
					searchable: false
				},{
					dataField: 'numbersProcessed',
					text: 'Calls Dialed',
					sort: false,
					searchable: false
				},{
					dataField: 'callsSubmitted',
					text: 'Pending Calls',
					sort: false,
					searchable: false
				},{
					dataField: 'callsConnected',
					text: 'Connected Calls',
					sort: false,
					searchable: false
				},{
					dataField: 'dtmfCount',
					text: 'Dtmf Count',
					sort: true,
					searchable: false
				},];

			runningCampaignSummary = Array.isArray(runningCampaigns) ? runningCampaigns.map((campaign, index) => {
				
				return {
					id: index + 1,
					campaignId: campaign.campaignId,
					name: campaign.campaignName,
					scheduleTime: campaign.scheduleTime,
					numbersUploaded: campaign.numbersUploaded,
					numbersProcessed: campaign.numbersProcessed,
					callsSubmitted: campaign.numbersUploaded - campaign.dndCount - campaign.numbersProcessed,
					callsConnected: campaign.callsConnected,
					dtmfCount: campaign.dtmfCount
				};
			}) : []
		} else if (this.props.app.role === 'ROLE_ADMIN') {
			runningCampaigns = this.state.resellerRunningCampaignList;
			runningColumns = [
				{
					dataField: 'id',
					text: 'Serial No.',
					sort: false,
					hidden: true,
					csvExport: false
				},
				{
					dataField: 'campaignId',
					text: 'Campaign Id',
					sort: false,
					hidden: false,
					csvExport: true
				},
				{
					dataField: 'campaignName',
					text: 'Campaign Name',
					sort: true,
					searchable: true,
					csvExport: false
				},
				{
					dataField: 'userId',
					text: 'User Id',
					sort: false,
					hidden: false,
					csvExport: true
				},
				{
					dataField: 'username',
					text: 'User Name',
					sort: true,
					searchable: true,
					csvExport: false
				},
				
				{
					dataField: 'name',
					text: 'Campaign Name',
					sort: true,
					searchable: true,
					hidden: true,
					csvExport: true
				}, {
					dataField: 'campaignstatus',
					text: 'Campaign Status',
					sort: true,
					searchable: true
				},
				{
					dataField: 'channels',
					text: 'Channels',
					sort: true,
					searchable: true
				}, {
					dataField: 'templateId',
					text: 'Campaign Type',
					sort: true,
					searchable: true
				},{
					dataField: 'scheduleTime',
					text: 'Start Time',
					sort: true,
					searchable: false
				},{
					dataField: 'endTime',
					text: 'End Time',
					sort: true,
					searchable: false
				}, {
					dataField: 'numbersUploaded',
					text: 'Total Numbers',
					sort: false,
					searchable: false
				}, {
					dataField: 'numbersProcessed',
					text: 'Calls Dialed',
					sort: false,
					searchable: false
				}, {
					dataField: 'callsSubmitted',
					text: 'Pending Calls',
					sort: false,
					searchable: false,
					csvExport: false
				},{
					dataField: 'callsSubmittedCsv',
					text: 'Pending Calls',
					sort: false,
					searchable: false,
					hidden: true,
					csvExport: true
				},{
					dataField: 'callsConnected',
					text: 'Connected Calls',
					sort: false,
					searchable: false,
					csvExport: false
				},{
					dataField: 'callsConnectedCsv',
					text: 'Connected Calls',
					sort: false,
					searchable: false,
					hidden: true,
					csvExport: true
				},{
					dataField: 'pulses',
					text: 'Total Pulses',
					sort: true,
					searchable: false
				},{
					dataField: 'dndCount',
					text: 'DnD Count',
					sort: true,
					searchable: false
				},{
					dataField: 'dtmfCount',
					text: 'Dtmf Count',
					sort: true,
					searchable: false,
					csvExport: false
				},{
					dataField: 'dtmf1Count',
					text: 'Dtmf1 Count',
					sort: true,
					searchable: false,
					csvExport: false
				},{
					dataField: 'dtmf2Count',
					text: 'Dtmf2 Count',
					sort: true,
					searchable: false,
					csvExport: false
				},{
					dataField: 'dtmfCountCsv',
					text: 'Dtmf Count',
					sort: true,
					searchable: false,
					hidden: true,
					csvExport: true
				},{
					dataField: 'dtmf1CountCsv',
					text: 'Dtmf1 Count',
					sort: true,
					searchable: false,
					hidden: true,
					csvExport: true
				},{
					dataField: 'dtmf2CountCsv',
					text: 'Dtmf2 Count',
					sort: true,
					searchable: false,
					hidden: true,
					csvExport: true
				},{
					dataField: 'smsCount',
					text: 'SMS Count',
					sort: true,
					searchable: false
				},{
					dataField: 'smsCountCsv',
					text: 'SMS Count',
					sort: true,
					searchable: false,
					hidden: true,
					csvExport: true
				},{
					dataField: 'retryCount',
					text: 'Retry Count',
					sort: true,
					searchable: false
				},{
					dataField: 'retries',
					text: 'Is Retry',
					sort: true,
					searchable: false,
					hidden: true
				},{
					dataField: 'retries',
					text: 'No. Of retries',
					sort: true,
					searchable: false
				},{
					dataField: 'fullReportStatus',
					text: 'Action',
					hidden: true,
					sort: false,
					searchable: false,
					csvExport: false
				},];

			runningCampaignSummary = Array.isArray(runningCampaigns) ? runningCampaigns.map((campaign, index) => {
				let template = '';
	
				switch (campaign.templateId) {
					case 0:
						template = 'Simple IVR';
						break;
					case 1:
						template = 'DTMF';
						break;
					case 2:
						template = 'Call Patch';
						break;
					case 3:
						template = 'Custom IVR';
						break;
					case 4:
						template = 'Multi IVR';
						break;
					default:
						template = '';
						break;
				}
				let status = '';
	
				switch (campaign.campaignStatus) {
					case 0:
						status = 'Ready';
						break;
					case 21:
						status = 'Processing';
						break;
					case 1:
						status = 'Running';
						break;
					case 2:
						status = 'Complete';
						break;
					case 3:
						status = 'Low Balance';
						break;
					case 4:
						status = 'Error';
						break;
					case 5:
						status = 'Error';
						break;
					case 6:
						status = 'Cancelling';
						break;
					case 7:
						status = 'Cancelled';
						break;
					case 8:
						status = 'Pausing';
						break;
					case 9:
						status = 'Paused';
						break;
					case 10:
						status = 'Resuming';
						break;
					case 22:
						status = 'Waiting For Channels';
							break;
					default:
						status = '';
						break;
				}
				
				let action = [];
				let retry = [];
				//let 
				action.push(<Dropdown id="dd">
					<Dropdown.Toggle className='btn-sm'>Take Action</Dropdown.Toggle>
						<Dropdown.Menu>
							{/* <Dropdown.Item key={`clone-campaign-${index}`} href={`${this.props.app.urlPrefix}/clone-campaign/${campaign.campaignId}`}>Clone Campaign</Dropdown.Item> */}
							{(campaign.campaignStatus === 1) && <Dropdown.Item key={`ps-campaign-${index}`} onClick={() => this.confirmPause(true, campaign.campaignId)} href="#">Pause Campaign</Dropdown.Item>}
							{(campaign.campaignStatus === 3 || campaign.campaignStatus === 9 || campaign.campaignStatus === 22) && <Dropdown.Item key={`rs-campaign-${index}`} onClick={() => this.confirmResume(true, campaign.campaignId)} href="#">Resume Campaign</Dropdown.Item>}
							{(campaign.campaignStatus === 1) && <Dropdown.Item key={`st-campaign-${index}`} onClick={() => this.confirmStop(true, campaign.campaignId)} href="#">Stop Campaign</Dropdown.Item>}
							{(campaign.campaignStatus === 9) && <Dropdown.Item key={`ps-campaign-${index}`} onClick={() => this.confirmEditChannels(true, campaign.campaignId)} href="#">Edit Channels</Dropdown.Item>}
							{/* {(campaign.fullReportStatus === '2' || campaign.fullReportStatus === undefined) && <Dropdown.Item id={`greport-${index}`} key={`greport-${index}`} onClick={() => this.generateReport(index, campaign.campaignId, 'full')} href="#">Generate Full Report</Dropdown.Item>} */}
						</Dropdown.Menu>
					  </Dropdown>);
					// if(campaign.campaignStatus === 2 && campaign.isRetry === 0)
					// {
					// 	retry.push(<Link data-tip="Retry" key={`${index}-retry`} className="btn btn-sm btn-primary" onClick={(e) => { this.confirmRetry(true, campaign.campaignId) }} href="#">Retry</Link>);
					// }
					let callsSubmitted=0;
					let dtmfDownload=[];
					dtmfDownload.push(campaign.dtmfCount);
					dtmfDownload.push(' ');
					if(campaign.templateId === 1 || campaign.templateId === 2 || campaign.templateId === 3 || campaign.templateId === 4)
					{
						if(campaign.dtmfReportStatus === '2' || campaign.dtmfReportStatus === undefined)
						{
							dtmfDownload.push( <a data-tip="" key={`${index}-dtmf`} className="download-report" onClick={( e ) => { this.generateReport( true, campaign.campaignId, 'dtmf' ) }} href="#"><i className="fa fa-download"></i></a>);
						}
					}
					let dtmf1Download=[];
					dtmf1Download.push(campaign.dtmf1Count);
					dtmf1Download.push(' ');
					if(campaign.templateId === 3 || campaign.templateId === 4)
					{
						if(campaign.dtmfReportStatus === '2' || campaign.dtmfReportStatus === undefined)
						{
							dtmf1Download.push( <a data-tip="" key={`${index}-dtmf1`} className="download-report" onClick={( e ) => { this.generateReport( true, campaign.campaignId, 'dtmf1' ) }} href="#"><i className="fa fa-download"></i></a>);
						}
					}
					let dtmf2Download=[];
					dtmf2Download.push(campaign.dtmf2Count);
					dtmf2Download.push(' ');
					if(campaign.templateId === 3 || campaign.templateId === 4)
					{
						if(campaign.dtmfReportStatus === '2' || campaign.dtmfReportStatus === undefined)
						{
							dtmf2Download.push( <a data-tip="" key={`${index}-dtmf2`} className="download-report" onClick={( e ) => { this.generateReport( true, campaign.campaignId, 'dtmf2' ) }} href="#"><i className="fa fa-download"></i></a>);
						}
					}
	
					let ccDownload=[];
					ccDownload.push(campaign.callsConnected);
					ccDownload.push(' ');
					if(campaign.callsConnected > 0)
					{
						if(campaign.ccReportStatus === '2' || campaign.ccReportStatus === undefined)
						{
							ccDownload.push( <a data-tip="" key={`${index}`} className="download-report" onClick={( e ) => { this.generateReport( true, campaign.campaignId, 'connected_calls' ) }} href="#"><i className="fa fa-download"></i></a>);
						}
					}
					let ccPending=[];
					ccPending.push(campaign.numbersUploaded - campaign.dndCount - campaign.numbersProcessed);
					ccDownload.push(' ');
					ccPending.push( <a data-tip="" key={`${index}-pending`} className="download-report" onClick={( e ) => { this.downloadPending( true, campaign.campaignId ) }} href="#"><i className="fa fa-download"></i></a>);
					
					let smsDownload = [];
					smsDownload.push(campaign.smsCount);
					smsDownload.push(' ');
					if(campaign.smsCount > 0){
						if(campaign.smsStatus == 2 || campaign.smsStatus == undefined){
							smsDownload.push( <a data-tip="" key={`${index}-sms`} className="download-report" onClick={( e ) => { this.generateReport( true, campaign.campaignId, 'sms' ) }} href="#"><i className="fa fa-download"></i></a>);
						}
					}
	
				return {
					id: index + 1,
					campaignId: campaign.campaignId,
					name: campaign.campaignName,
					userId: campaign.userId,
					username: campaign.username,
					campaignName: (campaign.templateId === 0 || campaign.templateId === 1 || campaign.templateId === 2)?<Link key={`${index}-cm`} to={`${this.props.app.urlPrefix}/voice-campaign-info/${campaign.campaignId}`}>{campaign.campaignName}</Link>:campaign.campaignName,
					campaignstatus: status,
					channels: campaign.channels,
					templateId: template,
					scheduleTime: campaign.scheduleTime,
					endTime: campaign.endTime,
					numbersUploaded: campaign.numbersUploaded,
					numbersProcessed: campaign.numbersProcessed,
					callsSubmitted: (campaign.campaignStatus === 7 || campaign.campaignStatus === 9)?ccPending:campaign.numbersUploaded - campaign.dndCount - campaign.numbersProcessed,
					callsSubmittedCsv: campaign.numbersUploaded - campaign.dndCount - campaign.numbersProcessed,
					callsConnected: (campaign.campaignStatus === 2)?ccDownload:campaign.callsConnected,
					callsConnectedCsv: campaign.callsConnected,
					pulses: campaign.pulses,
					dndCount: campaign.dndCount,
					dtmfCount: (campaign.campaignStatus === 2)?dtmfDownload:campaign.dtmfCount,
					dtmf1Count: (campaign.campaignStatus === 2)?dtmf1Download:campaign.dtmf1Count,
					dtmf2Count: (campaign.campaignStatus === 2)?dtmf2Download:campaign.dtmf2Count,
					dtmfCountCsv: campaign.dtmfCount,
					dtmf1CountCsv: campaign.dtmf1Count,
					dtmf2CountCsv: campaign.dtmf2Count,
					smsCount: (campaign.campaignStatus === 2) ? smsDownload : campaign.smsCount,
					smsCountCsv: campaign.smsCount,
					retryCount: campaign.retryCount,
					retries: (campaign.retries > 0)?'Yes':'No',
					retries: campaign.retries,
					fullReportStatus: action
				};
			}) : []
		}
		const paginationOption2 = {
			custom: true,
			totalSize: runningCampaignSummary.length,
			sizePerPage: this.state.todayLiveCampSumm_S_P_P,
			sizePerPageList: [10, 25, 50, 100],
			onSizePerPageChange: (newSizePerPage) => this.handleSizePerPageChange(newSizePerPage, 'todayLiveCampSumm_S_P_P')
		};
		const defaultSorted2 = [{
			dataField: 'scheduletime',
			order: 'desc'
		}];
		const statisticSummary = this.state.statisticSummaryList;
		let statisticColumns = [];
		if(this.props.app.role === 'ROLE_ADMIN') {
			statisticColumns = [
				{
					dataField: 'id',
					text: 'Serial No.',
					sort: false,
					hidden: true
				},
				{
					dataField: 'userName',
					text: 'Username',
					sort: false,
					searchable: true
				},
				{
					dataField: 'totalCampaigns',
					text: 'Total Campaigns',
					sort: true,
					searchable: false
				},{
					dataField: 'totalData',
					text: 'Total Data',
					sort: false,
					searchable: false
				},{
					dataField: 'totalConnectedData',
					text: 'Total Conected Data',
					sort: false,
					searchable: false
				},{
					dataField: 'totalPulses',
					text: 'Total Pulses',
					sort: false,
					searchable: false
				},{
					dataField: 'totalCost',
					text: 'Total Cost (INR)',
					sort: false,
					searchable: false
				}];
		} else {
			statisticColumns = [
				{
					dataField: 'id',
					text: 'Serial No.',
					sort: false,
					hidden: true
				},
				{
					dataField: 'totalCampaigns',
					text: 'Total Campaigns',
					sort: true,
					searchable: false
				},{
					dataField: 'totalData',
					text: 'Total Data',
					sort: false,
					searchable: false
				},{
					dataField: 'totalConnectedData',
					text: 'Total Conected Data',
					sort: false,
					searchable: false
				},{
					dataField: 'totalPulses',
					text: 'Total Pulses',
					sort: false,
					searchable: false
				},{
					dataField: 'totalCost',
					text: 'Total Cost (INR)',
					sort: false,
					searchable: false
				}];
			}

		const statisticSummaryData = Array.isArray(statisticSummary) ? statisticSummary.map((summary, index) => {
			
			return {
				id: index + 1,
				userName: summary.userName,
				totalCampaigns: summary.totalCampaigns,
				totalData: summary.totalNosProcessed,
				totalConnectedData: summary.totalConnectedCalls,
				totalPulses: summary.totalPulses,
				totalCost: summary.totalCost
			};
		}) : []
		const paginationOption3 = {
			custom: true,
			totalSize: statisticSummaryData.length,
			sizePerPage: this.state.statSummary_S_P_P,
			sizePerPageList: [10, 25, 50, 100],
			onSizePerPageChange: (newSizePerPage) => this.handleSizePerPageChange(newSizePerPage, 'statSummary_S_P_P')
		};

		const resellerUsersList = cloneDeep(this.state.resellerUserList);
		let usersDropdown = '';
		if (!isEmpty(resellerUsersList) && (!('error' in resellerUsersList))) {
			usersDropdown = Array.isArray(resellerUsersList) ? resellerUsersList.map((obj, index) => {
				return <option value={obj.username} key={`planid${index}`} >{obj.username}</option>
			}) : []

		} else {
			usersDropdown = <option value="" >No User found</option>
		}

		const campaignAnalysis = this.state.campaignAnalysisList;
		const campaignnalysisColumns = [
			{
				dataField: 'id',
				text: 'Serial No.',
				sort: false,
				hidden: true
			},
			{
				dataField: 'campaignName',
				text: 'Campaign Name',
				sort: true,
				searchable: true
			},{
				dataField: 'campaignType',
				text: 'Campaign Type',
				sort: false,
				searchable: true
			},{
				dataField: 'answered',
				text: 'Answered',
				sort: false,
				searchable: false
			},{
				dataField: 'dtmf',
				text: 'DTMF',
				sort: false,
				searchable: false
			},{
				dataField: 'nonAnswered',
				text: 'Non Answered',
				sort: false,
				searchable: false
			},{
				dataField: 'expenditure',
				text: 'Expenditure (INR)',
				sort: false,
				searchable: false
			}];

		const campaignTypeMapping = {
			basic: 'Simple IVR',
			interactive: 'DTMF',
			callpatch: 'Call Patch',
			custom: 'Custom IVR'
			};

		const campaignAnalysisData = Array.isArray(campaignAnalysis) ? campaignAnalysis.map((campaign, index) => {
			
			return {
				id: index + 1,
				campaignName: campaign.campaignName,
				campaignType: campaignTypeMapping[campaign.campaignType] || campaign.campaignType,
				answered: campaign.answered,
				dtmf: campaign.dtmf,
				nonAnswered: campaign.nonAnswered,
				expenditure: campaign.expenditure
			};
		}) : []
		const paginationOption4 = {
			custom: true,
			totalSize: campaignAnalysis.length,
			sizePerPage: this.state.campaignAnalysis_S_P_P,
			sizePerPageList: [10, 25, 50, 100],
			onSizePerPageChange: (newSizePerPage) => this.handleSizePerPageChange(newSizePerPage, 'campaignAnalysis_S_P_P')
		};

		const uniqueCampaignNames = Array.isArray(campaignAnalysis) 
			? [...new Set(campaignAnalysis.map(campaign => campaign.campaignName))] 
			: [];
       
		const campaignNameDropdown = uniqueCampaignNames.map(name => (
			<option key={name} value={name}>{name}</option>
		));

		const campaignAnalysisGraphSum = campaignAnalysisData.reduce((acc,curr) => {
			acc.answered += curr.answered;
			acc.dtmf += curr.dtmf;
			acc.nonAnswered += curr.nonAnswered;
			acc.expenditure += curr.expenditure;
			return acc;
		}, {answered: 0, nonAnswered: 0, dtmf: 0, expenditure: 0});

		const campaignAnalysisPieChartData = [
			{
				name: 'Answered',
				value: campaignAnalysisGraphSum.answered
			},
			{
				name: 'DTMF',
				value: campaignAnalysisGraphSum.dtmf
			},
			{
				name: 'Non Answered',
				value: campaignAnalysisGraphSum.nonAnswered
			},
			{
				name: 'Expenditure',
				value: campaignAnalysisGraphSum.expenditure
			}
		];

		const campaignAnalysisPieColours = ['#7dfc5c','#ffd700', '#ff6666', '#5c7dfc'];

		const callDetails = this.state.callDetailsList;
		const callDetailsColumns = [
			{
				dataField: 'id',
				text: 'Serial No.',
				sort: false,
				hidden: true
			},
			{
				dataField: 'totalCalls',
				text: 'Total Calls',
				sort: true,
				searchable: true
			},{
				dataField: 'totalConnectedCalls',
				text: 'Total Connected Calls',
				sort: false,
				searchable: true
			}];

		const callDetailsData = callDetails ? [{
			id: 1,
			totalCalls: callDetails.totalCalls,
			totalConnectedCalls: callDetails.totalConnectedCalls,
		}] : [];
		
		const paginationOption5 = {
			custom: true,
			totalSize: callDetails.length,
			sizePerPage: this.state.callDetail_S_P_P,
			sizePerPageList: [10, 25, 50, 100],
			onSizePerPageChange: (newSizePerPage) => this.handleSizePerPageChange(newSizePerPage, 'callDetail_S_P_P')
		};

		const dialerUsage = this.state.dialerUsageList;
		const dialerUsageColumns = [
			{
				dataField: 'id',
				text: 'Serial No.',
				sort: false,
				hidden: true
			},
			{
				dataField: 'dialerName',
				text: 'Dialer Name',
				sort: true,
				searchable: true
			},{
				dataField: 'cli',
				text: 'CLI',
				sort: false,
				searchable: true
			},{
				dataField: 'totalDurations',
				text: 'Total Durations (in sec)',
				sort: false,
				searchable: false
			}];

		const dialerUsageData = Array.isArray(dialerUsage) ? dialerUsage.map((dialer, index) => {
			
			return {
				id: index + 1,
				dialerName: dialer.dialerName,
				cli: dialer.cli,
				totalDurations: dialer.totalDurations
			};
		}) : []
		const paginationOption6 = {
			custom: true,
			totalSize: dialerUsage.length,
			sizePerPage: this.state.dialerUsage_S_P_P,
			sizePerPageList: [10, 25, 50, 100],
			onSizePerPageChange: (newSizePerPage) => this.handleSizePerPageChange(newSizePerPage, 'dialerUsage_S_P_P')
		};

		const { ExportCSVButton } = CSVExport;

		return (
			<div className="animated fadeIn">
				<div className="cr-page__header"><h1 className="h1 cr-page__title">Dashboard</h1></div>
				{/* {this.props.app.role === 'ROLE_USER' && <Row>
					<Col xs	="12" sm="6" lg="3">
						<Card className="dashboard-card">
							<Card.Body className="pb-0">
								<div className="dashboard-card-heading">Total Voice Campaigns</div>
								<div className="text-value dashboard-card-data">{this.state.dashboardData.totalVoiceCampaigns}</div>

							</Card.Body>
						</Card>
					</Col>
					<Col xs="12" sm="6" lg="2">
						<Card className="dashboard-card">
							<Card.Body className="pb-0">
								<div className="dashboard-card-heading">Today's Calls</div>
								<div className="text-value dashboard-card-data">{this.state.dashboardData.totalCalls}</div>

							</Card.Body>
						</Card>
					</Col>
					<Col xs="12" sm="6" lg="4">
						<Card className=" dashboard-card">
							<Card.Body className="pb-0">
								<div className="dashboard-card-heading">Today's Connected Calls</div>
								<div className="text-value dashboard-card-data">{this.state.dashboardData.connectedCalls}</div>

							</Card.Body>
						</Card>
					</Col>
					
					<Col xs="12" sm="6" lg="3">
						<Card className="dashboard-card">
							<Card.Body className="pb-0">
								<div className="dashboard-card-heading">Balance Available(Rs)</div>
								<div className="text-value dashboard-card-data">{this.state.dashboardData.voiceCreditsAvailable}</div>
							</Card.Body>
						</Card>
					</Col>
				</Row>} */}
				{(this.props.app.role === 'ROLE_ADMIN') && <Row>
					<Col xs="12" sm="6" lg="3">
						<Card className="dashboard-card">
							<Card.Body className="pb-0">
								<div className="dashboard-card-heading">Total Resellers</div>
								<Row className="align-items-center mt-2">
									<Col xs="4">
									<FaUserTie className="dashboard-card-icon" />
									</Col>
									<Col xs="8">
									<div className="text-value dashboard-card-data">{this.state.dashboardData.resellers}</div>
									</Col>
								</Row>
								
							</Card.Body>
						</Card>
					</Col>
					<Col xs="12" sm="6" lg="3">
						<Card className="dashboard-card">
							<Card.Body className="pb-0">
								<div className="dashboard-card-heading">Total End Users</div>
								<Row className="align-items-center mt-2">
									<Col xs="4">
									<FaUsers className="dashboard-card-icon" />
									</Col>
									<Col xs="8">
									<div className="text-value dashboard-card-data">{this.state.dashboardData.endUsers}</div>
									</Col>
								</Row>

							</Card.Body>
						</Card>
					</Col>

					{/* <Col xs="12" sm="6" lg="3">
						<Card className=" dashboard-card">
							<Card.Body className="pb-0">
								<div className="dashboard-card-heading">Credits Available</div>
								<div className="text-value dashboard-card-data">{this.state.dashboardData.voiceCreditsAvailable}</div>
							</Card.Body>
						</Card>
					</Col> */}
				</Row>}
				{(this.props.app.role === 'ROLE_SADMIN') && <Row className='mb-3'>
					<Col xs="12" sm="6" lg="3">
						<Card className="dashboard-card">
							<Card.Body className="pb-0">
								<div className="dashboard-card-heading">Total Resellers</div>
							    <Row className="align-items-center mt-2">
									<Col xs="4">
									<FaUserTie className="dashboard-card-icon" />
									</Col>
									<Col xs="8">
									<div className="text-value dashboard-card-data">{this.state.dashboardData.resellers}</div>
									</Col>
								</Row>
							</Card.Body>
						</Card>
					</Col>

					<Col xs="12" sm="6" lg="3">
						<Card className=" dashboard-card">
							<Card.Body className="pb-0">
								<div className="dashboard-card-heading">Total End Users</div>
								<Row className="align-items-center mt-2">
									<Col xs="4">
									<FaUsers className="dashboard-card-icon" />
									</Col>
									<Col xs="8">
									<div className="text-value dashboard-card-data">{this.state.dashboardData.endUsers}</div>
									</Col>
								</Row>
							</Card.Body>
						</Card>
					</Col>
				</Row>}
				{(this.props.app.role === 'ROLE_USER' || this.props.app.role === 'ROLE_SADMIN' || this.props.app.role === 'ROLE_ADMIN') && <Row className="mb-3"></Row>}
				{(this.props.app.role === 'ROLE_USER' || this.props.app.role === 'ROLE_SADMIN' || this.props.app.role === 'ROLE_ADMIN') && <Row className="mb-3">
				<Col sm={12}>
					<Card>
					    {(this.props.app.role === 'ROLE_ADMIN') ? 
						<Card.Header>Today's Live Campaigns Summary</Card.Header> : <Card.Header>Today's Running Campaigns</Card.Header>}
						<Card.Body>
						<div className="user-list-table-cont">
						{<PaginationProvider pagination={paginationFactory(paginationOption2)}>
							{
								({
									paginationProps,
									paginationTableProps
								}) => (
									<ToolkitProvider
										keyField="scheduleDate"
										data={runningCampaignSummary}
										columns={runningColumns}
										search
										bootstrap4
									>
										{
											props => (
												<div className="">
													Show <SizePerPageDropdownStandalone className="search-dropdown" {...paginationProps} /> items
													<span className="search-cont"><SearchBar {...props.searchProps} /></span>
													<ClearSearchButton className="btn-primary btn-round" {...props.searchProps} />
													<hr />
													<BootstrapTable bordered={true} defaultSorted={defaultSorted2} striped={true} condensed wrapperClasses="table-responsive" classes="userCampaignList-table"  {...props.baseProps}  {...paginationTableProps} noDataIndication={() => (<div>{this.state.noDataDisplayMsg}</div>)}/>
													<PaginationListStandalone {...paginationProps} />
												</div>
											)
										}
									</ToolkitProvider>
								)
							}
						</PaginationProvider>}
					</div>
						</Card.Body>
					</Card>
					</Col>
				</Row>}
				{this.props.app.role === 'ROLE_USER' && <Row className="mb-3"></Row>}
				{this.props.app.role === 'ROLE_USER' && <Row className="mb-3">
				<Col sm={6}>
					<Card>
						{<Card.Header>Last 7 Days Expenditure</Card.Header>}
						<Card.Body>
        					<BarChart width={500} height={300} data={this.state.expenditureList}>
						  	<CartesianGrid strokeDasharray="3 3" />
        					<XAxis dataKey="campaignDate" interval={0} angle={-14} textAnchor="end"/>
        					<YAxis/>
        					<Tooltip />
        					<Legend />
        				<Bar dataKey="expenditure" fill="#fc5c7d" barSize={40} />
        				</BarChart>
					</Card.Body>
					</Card>
				</Col>
				<Col sm={6}>
					<Card>
						{<Card.Header>Last 7 Day Campaigns</Card.Header>}
						<Card.Body>
        					<BarChart width={500} height={300} data={this.state.weeklyCampaignList}>
						  	<CartesianGrid strokeDasharray="3 3" />
        					<XAxis dataKey="scheduleDate" interval={0} angle={-14} textAnchor="end"/>
        					<YAxis/>
        					<Tooltip />
        					<Legend />
        				<Bar dataKey="campaignCount" fill="#fc5c7d" barSize={40} />
        				</BarChart>
					</Card.Body>
					</Card>
				</Col>
				</Row>}
				{(this.props.app.role === 'ROLE_USER' || this.props.app.role === 'ROLE_ADMIN') && <Row className="mb-3"></Row>}
				{(this.props.app.role === 'ROLE_USER' || this.props.app.role === 'ROLE_ADMIN') && <Row className="mb-3">
				<Col sm={7}>
					<Card>
						{<Card.Header>Campaign Analysis</Card.Header>}
						<Card.Body>
						<div className="user-list-table-cont">
						<Form className="user-statistic-form" method='post' onSubmit={(e) => this.searchCampaignAnalysis(e)}> 
					        <Row>
						        <Col sm={4}>
                                    <Form.Group className="form-group col-md-12 float-left" style={{ paddingLeft: 0 }}>
					                    <DatePicker
						                    className="form-control"
						                    dateFormat="yyyy-MM-dd"
                                            selected={this.state.startDateCampaignAnalysis}
                                            onChange={(date) => this.handleDateChange('startDateCampaignAnalysis', date)}
                                            placeholderText="Start Date"
					                    />
				                    </Form.Group>
                                </Col>
                                <Col sm={4}>
                                    <Form.Group className="form-group col-md-12 float-left" style={{ paddingLeft: 0 }}>
					                    <DatePicker
						                    className="form-control"
						                    dateFormat="yyyy-MM-dd"
						                    selected={this.state.endDateCampaignAnalysis}
											onChange={(date) => this.handleDateChange('endDateCampaignAnalysis', date)}
                                            placeholderText="End Date"
					                    />
				                    </Form.Group>
                                </Col>
								{this.props.app.role === 'ROLE_ADMIN' &&
								<Col sm={4}>
									<Form.Group className="form-group  control-label">
										<Form.Select className="dd"  name="campAnalysisUsername" onChange={e => this.setvalue(e)} as="select">
										<option value="">Select User</option>
										{usersDropdown}
										</Form.Select>
									</Form.Group>
                                </Col>
								}
							</Row>
							<Row>
								<Col sm={4}>		
									<Form.Group className="form-group"  controlId="sender-id">
										{/* <Form.Label>Campaign Type</Form.Label> */}
										<Form.Select as="select" name="selectedCampaignType" onChange={e => this.setvalue(e)}>
										    <option value="">Select Campaign Type</option>
											<option value="All">All</option>
											<option value="basic">Simple IVR</option>
											<option value="interactive">DTMF</option>
											<option value="callpatch">Call Patch</option>
											<option value="custom">Custom IVR</option>
										</Form.Select>
									</Form.Group>
								</Col>
								<Col sm={4}>		
									<Form.Group className="form-group"  controlId="sender-id">
										{/* <Form.Label>Campaign Type</Form.Label> */}
										<Form.Select as="select" name="selectedCampaignName" onChange={e => this.setvalue(e)}>
										    <option value="">Select Campaign Name</option>
											<option value="All">All</option>
											{campaignNameDropdown}
										</Form.Select>
									</Form.Group>
								</Col>
								<Col sm={2}>
										<Button variant="primary" type="submit">Search</Button>
								</Col>
							</Row>
					    </Form> 
						{<PaginationProvider pagination={paginationFactory(paginationOption4)}>
							{
								({
									paginationProps,
									paginationTableProps
								}) => (
									<ToolkitProvider
										keyField="id"
										data={campaignAnalysisData}
										columns={campaignnalysisColumns}
										search
										bootstrap4
									>
										{
											props => (
												<div className="">
													Show <SizePerPageDropdownStandalone className="search-dropdown" {...paginationProps} /> items
													<span className="search-cont"><SearchBar  {...props.searchProps} /></span>
													<ClearSearchButton className="btn-primary btn-round" {...props.searchProps} />
													<hr />
													<BootstrapTable bordered={true} striped={true} condensed wrapperClasses="table-responsive" classes="userCampaignList-table"  {...props.baseProps}  {...paginationTableProps} noDataIndication={() => (<div>{this.state.noDataDisplayMsg}</div>)} />
													<PaginationListStandalone {...paginationProps} />
												</div>
											)
										}
									</ToolkitProvider>
								)
							}
						</PaginationProvider>}
					</div>
						</Card.Body>
					</Card>
					</Col>
					<Col sm={5}>
					<Card>
						{<Card.Header>Campaign Analysis</Card.Header>}
						<Card.Body>
							<ResponsiveContainer width="100%" height={350}>
								<PieChart>
									<Pie
										data={campaignAnalysisPieChartData}
										dataKey="value"
										nameKey="name"
										cx="50%"
										cy="50%"
										outerRadius={100}
										fill="#8884d8"
										label
									>
										{campaignAnalysisPieChartData.map((entry, index) => (
											<Cell key={`cell-${index}`} fill={campaignAnalysisPieColours[index % campaignAnalysisPieColours.length]} />
										))}
									</Pie>
									<Tooltip />
									<Legend />
								</PieChart>
							</ResponsiveContainer>
					</Card.Body>
					</Card>
				</Col>
				</Row>}
				{(this.props.app.role === 'ROLE_USER' || this.props.app.role === 'ROLE_ADMIN') && <Row className="mb-3"></Row>}
				{(this.props.app.role === 'ROLE_USER' || this.props.app.role === 'ROLE_ADMIN') && <Row className="mb-3">
				<Col sm={12}>
					<Card>
						{<Card.Header>Statistic Summary</Card.Header>}
						<Card.Body>
						<div className="user-list-table-cont">
						<Form className="user-statistic-form" method='post' onSubmit={(e) => this.searchStatSummary(e)}> 
					        <Row>
						        <Col sm={2}>
                                    <Form.Group className="form-group col-md-12 float-left" style={{ paddingLeft: 0 }}>
					                    <DatePicker
						                    className="form-control"
						                    dateFormat="yyyy-MM-dd"
                                            selected={this.state.startDate}
											onChange={(date) => this.handleDateChange('startDate', date)}
                                            placeholderText="Start Date"
					                    />
				                    </Form.Group>
                                </Col>
                                <Col sm={2}>
                                    <Form.Group className="form-group col-md-12 float-left" style={{ paddingLeft: 0 }}>
					                    <DatePicker
						                    className="form-control"
						                    dateFormat="yyyy-MM-dd"
						                    selected={this.state.endDate}
                                            onChange={(date) => this.handleDateChange('endDate', date)}
                                            placeholderText="End Date"
					                    />
				                    </Form.Group>
                                </Col>
                                <Col sm={2}>
                                    <Button variant="primary" type="submit">Search</Button>
                                </Col>
						    </Row>	
					    </Form> 
						{<PaginationProvider pagination={paginationFactory(paginationOption3)}>
							{
								({
									paginationProps,
									paginationTableProps
								}) => (
									<ToolkitProvider
										keyField="id"
										data={statisticSummaryData}
										columns={statisticColumns}
										search
										bootstrap4
									>
										{
											props => (
												<div className="">
													Show <SizePerPageDropdownStandalone className="search-dropdown" {...paginationProps} /> items
													<hr />
													<BootstrapTable bordered={true} striped={true} condensed wrapperClasses="table-responsive" classes="userCampaignList-table"  {...props.baseProps}  {...paginationTableProps} noDataIndication={() => (<div>{this.state.noDataDisplayMsg}</div>)} />
													<PaginationListStandalone {...paginationProps} />
												</div>
											)
										}
									</ToolkitProvider>
								)
							}
						</PaginationProvider>}
					</div>
						</Card.Body>
					</Card>
					</Col>
				</Row>}
				

				{(this.props.app.role === 'ROLE_SADMIN') && <Row className='mb-3'>
				<Col sm={12}>
					<Card>
						{<Card.Header>Today's Campaigns</Card.Header>}
						<Card.Body>
						<div className="user-list-table-cont">
						{<PaginationProvider pagination={paginationFactory(paginationOption1)}>
							{
								({
									paginationProps,
									paginationTableProps
								}) => (
									<ToolkitProvider
										keyField="scheduleDate"
										data={todayCampaigns}
										columns={todayColumns}
										search
										bootstrap4
									>
										{
											props => (
												<div className="">
													Show <SizePerPageDropdownStandalone className="search-dropdown" {...paginationProps} /> items
													<span className="search-cont"><SearchBar {...props.searchProps} /></span>
													<ClearSearchButton className="btn-primary btn-round" {...props.searchProps} />
													<hr />
													<BootstrapTable bordered={true} defaultSorted={defaultSorted1} striped={true} condensed wrapperClasses="table-responsive" classes="userCampaignList-table"  {...props.baseProps}  {...paginationTableProps} noDataIndication={() => (<div>{this.state.noDataDisplayMsg}</div>)} />
													<PaginationListStandalone {...paginationProps} />
												</div>
											)
										}
									</ToolkitProvider>
								)
							}
						</PaginationProvider>}
					</div>
						</Card.Body>
					</Card>
					</Col>
				</Row>}
				{(this.props.app.role === 'ROLE_SADMIN') && <Row>
					<Col sm={12}>
						<Card>
							{<Card.Header>Running Campaigns</Card.Header>}
							<Card.Body>
								<div className="user-list-table-cont">
									{<PaginationProvider pagination={paginationFactory(paginationOption)}>
										{
											({
												paginationProps,
												paginationTableProps
											}) => (
												<ToolkitProvider
													keyField="username"
													data={users}
													columns={columns}
													search
													bootstrap4
												>
													{
														props => (
															<div className="">
																Show <SizePerPageDropdownStandalone className="search-dropdown" {...paginationProps} /> items
																<span className="search-cont"><SearchBar  {...props.searchProps} /></span>
																<ClearSearchButton className="btn-primary btn-round" {...props.searchProps} />
																{/*<span>&nbsp;&nbsp;&nbsp;</span><span className="float-none avail-credit-cont"><span className="credits-avail">Available Credits: {this.state.adminCredits}</span></span>*/}
																<hr />
																<div style={{ height: '350px', overflowY: 'auto' }}>
																	<BootstrapTable bordered={true} striped={true} condensed wrapperClasses="table-responsive" classes="userCampaignList-table"  {...props.baseProps}  {...paginationTableProps} noDataIndication={() => (<div>{this.state.noDataDisplayMsg}</div>)} />
																</div>
																<PaginationListStandalone {...paginationProps} />

															</div>
														)
													}
												</ToolkitProvider>
											)
										}
									</PaginationProvider>}
								</div>
								<div className="campaign-list-table-cont" ref={this.viewCampaignRef}>
									{/* <h3>Campaign Summary</h3> */}
									{/*{!isEmpty( this.state.campaignlist ) && <PaginationProvider pagination={paginationFactory( paginationOption )}>*/}
									{<PaginationProvider pagination={paginationFactory(paginationOption)}>
										{
											({
												paginationProps,
												paginationTableProps
											}) => (<ToolkitProvider 
													keyField="id" 
													data={products} 
													columns={ccolumns}  
													bootstrap4
													>
												{props => (<div className="">
													<hr style={{ marginTop: '2rem'}}/>
													<BootstrapTable bordered={true} hover defaultSorted={defaultSorted} wrapperClasses="table-responsive" classes="w-auto campaignlist-table" {...props.baseProps} {...paginationTableProps} noDataIndication={() => (<div>{this.state.noDataDisplayMsg}</div>)} />
												</div>)}
											</ToolkitProvider>)
										}
									</PaginationProvider>}
								</div>
							</Card.Body>
						</Card>
					</Col>
				</Row>}

				{this.props.app.role === 'ROLE_SADMIN' && <Row className="mb-3"></Row>}
				{this.props.app.role === 'ROLE_SADMIN' && <Row className="mb-3">
				<Col sm={12}>
					<Card>
						{<Card.Header>Call Details</Card.Header>}
						<Card.Body>
							<div className="user-list-table-cont">
								<Form className="user-statistic-form" method='post' onSubmit={(e) => this.searchCallDetails(e)}> 
									<Row>
										<Col sm={2}>
											<Form.Group className="form-group col-md-12 float-left" style={{ paddingLeft: 0 }}>
												<DatePicker
													className="form-control"
													dateFormat="yyyy-MM-dd"
													selected={this.state.startDateCallDetails}
													onChange={(date) => this.handleDateChange('startDateCallDetails', date)}
													placeholderText="Start Date"
												/>
											</Form.Group>
										</Col>
										<Col sm={2}>
											<Form.Group className="form-group col-md-12 float-left" style={{ paddingLeft: 0 }}>
												<DatePicker
													className="form-control"
													dateFormat="yyyy-MM-dd"
													selected={this.state.endDateCallDetails}
													onChange={(date) => this.handleDateChange('endDateCallDetails', date)}
													placeholderText="End Date"
												/>
											</Form.Group>
										</Col>
										<Col sm={2}>
											<Button variant="primary" type="submit">Search</Button>
										</Col>
									</Row>	
								</Form> 
								<Row>
									<Col sm={6}>
										{<PaginationProvider pagination={paginationFactory(paginationOption5)}>
											{
												({
													paginationProps,
													paginationTableProps
												}) => (
													<ToolkitProvider
														keyField="id"
														data={callDetailsData}
														columns={callDetailsColumns}
														search
														bootstrap4
													>
														{
															props => (
																<div className="">
																	{/* Show <SizePerPageDropdownStandalone className="search-dropdown" {...paginationProps} /> items */}
																	<hr />
																	<BootstrapTable bordered={true} striped={true} condensed wrapperClasses="table-responsive" classes="userCampaignList-table"  {...props.baseProps}  {...paginationTableProps} noDataIndication={() => (<div>{this.state.noDataDisplayMsg}</div>)} />
																	<PaginationListStandalone {...paginationProps} />
																</div>
															)
														}
													</ToolkitProvider>
												)
											}
										</PaginationProvider>}
									</Col>
								</Row>
							</div>
						</Card.Body>
					</Card>
					</Col>
				</Row>}

				{this.props.app.role === 'ROLE_SADMIN' && <Row className="mb-3"></Row>}
				{ this.props.app.role === 'ROLE_SADMIN' && <Row className="mb-3">
				<Col sm={12}>
					<Card>
						{<Card.Header>Dialer Wise Total Usage</Card.Header>}
						<Card.Body>
						<div className="user-list-table-cont">
						<Form className="user-statistic-form" method='post' onSubmit={(e) => this.searchDialerUsage(e)}> 
					        <Row>
						        <Col sm={2}>
                                    <Form.Group className="form-group col-md-12 float-left" style={{ paddingLeft: 0 }}>
					                    <DatePicker
						                    className="form-control"
						                    dateFormat="yyyy-MM-dd"
                                            selected={this.state.startDateDialerUsage}
											onChange={(date) => this.handleDateChange('startDateDialerUsage', date)}
                                            placeholderText="Start Date"
					                    />
				                    </Form.Group>
                                </Col>
                                <Col sm={2}>
                                    <Form.Group className="form-group col-md-12 float-left" style={{ paddingLeft: 0 }}>
					                    <DatePicker
						                    className="form-control"
						                    dateFormat="yyyy-MM-dd"
						                    selected={this.state.endDateDialerUsage}
                                            onChange={(date) => this.handleDateChange('endDateDialerUsage', date)}
                                            placeholderText="End Date"
					                    />
				                    </Form.Group>
                                </Col>
                                <Col sm={2}>
                                    <Button variant="primary" type="submit">Search</Button>
                                </Col>
						    </Row>	
					    </Form> 
						{<PaginationProvider pagination={paginationFactory(paginationOption6)}>
							{
								({
									paginationProps,
									paginationTableProps
								}) => (
									<ToolkitProvider
										keyField="id"
										data={dialerUsageData}
										columns={dialerUsageColumns}
										search
										bootstrap4
									>
										{
											props => (
												<div className="">
													Show <SizePerPageDropdownStandalone className="search-dropdown" {...paginationProps} /> items
														<ExportCSVButton className="export-csv btn-primary ml-2" {...props.csvProps}>Export to CSV</ExportCSVButton>
													<hr />
													<BootstrapTable bordered={true} striped={true} condensed wrapperClasses="table-responsive" classes="userCampaignList-table"  {...props.baseProps}  {...paginationTableProps} noDataIndication={() => (<div>{this.state.noDataDisplayMsg}</div>)} />
													<PaginationListStandalone {...paginationProps} />
												</div>
											)
										}
									</ToolkitProvider>
								)
							}
						</PaginationProvider>}
					</div>
						</Card.Body>
					</Card>
					</Col>
				</Row>}
			</div>
		);
	}

}

export default Dashboard;