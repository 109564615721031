import React, { Component } from 'react';
import { Form, Row, Col, Card, Button } from 'react-bootstrap';
import { Redirect } from "react-router-dom";
import { Fetcher } from "../../../Helpers/fetcher.js";
import { Notify } from "../../../Helpers/notification.js";
import { cloneDeep, isEmpty } from 'lodash';
import "./index.css";

class AddTTSPlan extends Component {
	constructor(props) {
		super(props);
		let sitePath;
		let filePath;
		
		const protocol = window.location.protocol;
		const slashes = protocol.concat("//");
		sitePath = slashes.concat(window.location.hostname);
		this.state = {
			userId: props.app.userId,
			apiPath: props.app.apiPath,
			sitePath: sitePath,
			filePath: filePath,
			planAdded: false,
			pulseList: [],
			pulseDuration: '',
			planName: '',
			pulsePrice: '',
            perCharacter : '',
            perVariable : ''
            
		}

	}

	componentDidMount() {
	}


	toggleChange = (e, name) => {
		this.setState({
			[name]: !this.state[name],
		});
	}


	setvalue = (e) => {
		this.setState({
			[e.target.name]: e.target.value,
		});
	}

	setMessageShow = (status) => {
		this.setState({
		});
	}

	// Add Plan
	handleAddTTSPlanSubmit = (event) => {
		event.preventDefault();
		const formData = cloneDeep(this.state);
		console.log(formData);

		const data = {
			"userId": formData.userId,
			"planName": formData.planName,
			"perCharacter": Number(formData.perCharacter),
			"perVariable": Number(formData.perVariable)
		};

        // formData.apiPath
		return Fetcher.post(formData.apiPath + '/api/obd/tts/plan', { headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
			.then(res => {
				
				let planAdded = false;
				if (res.message === 'Parameters Incorrect') {
					Notify.notify(res.message);
				} else {
					Notify.notify(res.message);
					planAdded = true;
				}
				this.setState({
					planAdded: planAdded
				})
			})
			.catch(err => {
				console.log('Error in Creating TTS Plan', err);
				Notify.notify('Error in Creating TTS Plan');
			});
	}

	addZero = (i) => {
		if (i < 10) {
			i = "0" + i;
		}
		return i;
	}

	render() {
		if (this.state.planAdded === true) {
			return <Redirect to='/tts-plans' />
		}
		let submitButtonlabel = 'Add Plan';
		
		return (

			<Form className="add-campaign-form" onSubmit={(e) => this.handleAddTTSPlanSubmit(e)}>
				<Row>
					<Col sm={12}>
						<Card>
							<Card.Header>Add TTS Plan</Card.Header>
							<Card.Body>
								<Row>
									<Col sm={4}>
										<Form.Group className="form-group"  controlId="plan-name">
											<Form.Label>Plan Name</Form.Label>
											<Form.Control required name="planName" onChange={e => this.setvalue(e)} value={this.state.planName} type="text" placeholder="Plan Name" />
										</Form.Group>
									</Col>
									<Col sm={4}>
										<Form.Group className="form-group"  controlId="per-character">
											<Form.Label>Per Character</Form.Label>
											<Form.Control required name="perCharacter" onChange={e => this.setvalue(e)} value={this.state.perCharacter} type="text" placeholder="Per Character" />
										</Form.Group>
									</Col>
                                    <Col sm={4}>
										<Form.Group className="form-group"  controlId="per-variable">
											<Form.Label>Per Variable</Form.Label>
											<Form.Control required name="perVariable" onChange={e => this.setvalue(e)} value={this.state.perVariable} type="text" placeholder="Per Variable" />
										</Form.Group>
									</Col>
								</Row>
								<Button variant="primary" type="submit">{submitButtonlabel}</Button>
							</Card.Body>
						</Card>
					</Col>
				</Row>
			</Form>
		);
	}
}

export default AddTTSPlan;